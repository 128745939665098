.baniere .owl-carousel {
    position: relative;
}



@media only screen and (min-width: 991px) {

    .baniere .owl-item {
        height: 100vh !important;
    }
}

@media only screen and (max-width: 991px) {

    .baniere .owl-nav,
    .coming-events .owl-dots {
        display: none;
    }

    .baniere .owl-dots {
        left: 50%;
        bottom: -5% !important;


    }
}

.baniere .owl-nav {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 45%;
}

.baniere .owl-next {
    position: absolute;
    right: 0;
    background-color: rgba(4, 4, 4, 0.7) !important;
    width: 50px;
}

.baniere .owl-next span,
.baniere .owl-prev span {
    color: white;
    font-size: 2rem;
    font-weight: bold;
}

.baniere .owl-prev {
    position: absolute;
    left: 0;
    background-color: rgba(4, 4, 4, 0.7) !important;
    width: 50px;
}

.baniere .owl-dots {
    position: absolute;
    z-index: 1;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* --------------------Les differents secteurs d'interventions ET EVENEMENT A VENIR------- */


.secteur .owl-nav {
    display: none;
}

.secteur .owl-stage {
    padding-bottom: 20px;
}

.secteurs .owl-stage {
    padding-bottom: 50px;
}

.secteurs .owl-nav {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 45%;
}


.secteurs .owl-next {
    position: absolute;
    right: -60px;
    background-color: rgba(4, 4, 4, 0.7) !important;
    width: 40px;
    height: 40px;
    border-radius: 30px !important;
}

.secteurs .owl-prev {
    position: absolute;
    left: -60px;
    background-color: rgba(4, 4, 4, 0.7) !important;
    width: 40px;
    height: 40px;
    border-radius: 30px !important;
}

.secteurs .owl-dots {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 50%;
}

.overlay-group:hover .secteur-overlay {
    transition: all .5s;
    transition-timing-function: ease-in-out;
}