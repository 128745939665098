@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #e68106;
  --secondary: #a0a0a1;
  box-sizing: border-box;
}

button {
  @apply text-white text-sm rounded-sm cursor-pointer bg-primary font-semibold px-4 py-1.5 xl:py-3 hover:opacity-90 hover:scale-110 hover:duration-300 hover:ease-in-out
}

button .active {
  @apply text-white
}

.opportunies img {
  @apply h-[80%] w-full
}

li {
  cursor: pointer;
}

.PhoneInputInput {
  @apply bg-transparent outline-none
}

.mobile-nav2 .mobile-dropdown li {
  @apply my-6 py-2 border-b border-white
}

.club-advantages button {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.btn-center {
  position: relative;
  left: 32%;
}

.padding_li li {
  padding-top: 10px;
  padding-bottom: 10px;
}



body {
  background-color: #e4e4e7;
}