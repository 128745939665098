.navbar {
    position: sticky;
    top: 0;
    z-index: 100;
}

.mobile-nav,
.mobile-nav2 {
    background-color: #e4e4e7;
    color: white;
    box-shadow: rgba(43, 42, 42, 0.2) 0px 60px 40px -7px;

}

.mobile-nav .dropdown-2-content li {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {

    .mobile-nav button .active {
        color: var(--primary);
    }

}

.mobile-dropdown {
    overflow-y: scroll;
}

.top-header {
    position: relative;
    z-index: 2;
}


.nav1 {
    background-color: transparent;
    color: black;
}


.nav1 .logo,
.nav11 .logo {
    width: 30% !important;

}

/* Acceuil Header nav */

.nav11 {
    background-color: transparent;
    color: white;
}


.nav2 {
    background-color: #e4e4e7;
    box-shadow: 0px 0px 2px rgb(137, 134, 134);
}

.active {
    color: rgb(165, 22, 22);
}



.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown-content {
    width: 550px;
    background-color: white;
    position: absolute;
    padding: 30px 30px 30px 15px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    box-shadow: rgba(171, 169, 169, 0.2) 0px 60px 40px -7px;
    color: black;
}

.dropdown-2-content {
    background-color: white;
    position: absolute;
    width: 80%;
    left: 0%;
    box-shadow: rgba(171, 169, 169, 0.2) 0px 60px 40px -7px;
    color: black;
    text-align: start;
    opacity: 1;
    z-index: 100;
}

.dropdown-w2 {
    width: 500px;
}

.dropdown-w3 {
    width: 430px;
}

.dropdown-content ul li {
    padding: 10px 0px;
}

.dropdown-right {
    right: 0;
    left: unset;
    transform: unset;
}